<template>
  <div>
    <v-main>
      <v-container class="mt-8 mb-12">

        <div class="text-h4 mb-8" style="word-wrap: anywhere">Literaturempfehlungen</div>

        <div v-for="(book, index) in books" :key="book.title">
          <v-divider class="my-12" v-if="index > 0"></v-divider>
          <div class="d-flex flex-wrap2">
            <a :href="book.link" target="_blank" class="mr-8">
              <v-sheet elevation="2">
                <v-img :src="getImage(book.image)" width="260" max-width="260"></v-img>
              </v-sheet>
            </a>
            <div class="d-flex flex-column">
              <p class="text-h6">{{ book.title }}</p>
              <p>Von {{ book.author }}</p>
              <div v-html="book.text"></div>
              <div>
                <v-btn color="primary" class="mt-4" :href="book.link" target="_blank">
                  Buch bei Amazon bestellen
                </v-btn>
              </div>
            </div>
          </div>
        </div>



      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "Literaturempfehlungen",
  data: () => ({
    books: [
      {
        title: "Wie man Freunde gewinnt: Die Kunst, beliebt und einflussreich zu werden",
        author: "Dale Carnegie",
        text: "Dieses Buch zeigt Ihnen: <ul> <li>wie man Freunde gewinnt</li> <li>wie man auf neuen Wegen zu neuen Zielen gelangt</li> <li>wie man beliebt wird</li> <li>wie man seine Umwelt beeinflußt</li> <li>wie man mehr Ansehen erlangt</li> <li>wie man im Beruf erfolgreicher wird</li> <li>wie man Streit vermeidet</li> <li>wie man ein guter Redner und brillanter Gesellschafter wird</li> <li>wie man den Charakter seiner Mitmenschen erkennt</li> <li>wie man seine Mitarbeiter anspornt</li> </ul> und vieles mehr…",
        image: "carnegie.jpg",
        link: "https://www.amazon.de/gp/product/3596190533/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3596190533&linkCode=as2&tag=karriereinsti-21&linkId=5a37d9018796f200acdf5b9d6b8aa268"
      },
      {
        title: "Die Entscheidung liegt bei dir!: Wege aus der alltäglichen Unzufriedenheit",
        author: "Reinhard K. Sprenger",
        text: "<p>Anleitung für ein gelungenes Leben</p> Nur zu schnell richten wir uns ein in der alltäglichen Unzufriedenheit und fühlen uns als ohnmächtiges Opfer der Sachzwänge. Aber ein gelungenes Leben ist keine Glückssache, es verlangt vielmehr selbstverantwortliches und entschiedenes Handeln. Wie man sein Glück beim Schopf packt, zeigt Reinhard K. Sprenger in diesem aufrüttelnden Buch, das jetzt um ein neues Kapitel zum Thema Burn-out erweitert wird. Es ist Zeit, die eigenen Wünsche und Bedürfnisse ernst zu nehmen und seine Zukunft in die Hände der Person zu legen, die einem am besten vertraut ist: sich selbst.",
        image: "sprenger.jpg",
        link: "https://www.amazon.de/gp/product/3593505371/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3593505371&linkCode=as2&tag=karriereinsti-21&linkId=4b01ec073c535824d32f0f256140380d"
      },
      {
        title: "Wenn du es eilig hast, gehe langsam: Wenn du es noch eiliger hast, mache einen Umweg. Der Klassiker des Zeitmanagements mit neuen Tools",
        author: "Lothar Seiwert",
        text: "Lothar Seiwerts Klassiker für ganzheitliches Selbstmanagement und aktive Lebensgestaltung erfreut Leserinnen und Leser seit mittlerweile 20 Jahren. Und das feiern wir! Mit einer vollständig überarbeiteten und aktualisierten Ausgabe in attraktiver, frischer Optik.<br>Lothar Seiwert und Werner Tiki Küstenmacher lassen Hase und Schildkröte weiter um die Wette rennen und zeigen uns, wie wir das echte gute Leben ganz ohne Kampf erreichen.",
        image: "Wenn-du-es-eilig-hast.jpg",
        link: "https://www.amazon.de/gp/product/B07CYNVQ4T/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B07CYNVQ4T&linkCode=as2&tag=karriereinsti-21&linkId=a8f91a4edc7610fe387c705ba89f8441"
      },
      {
        title: "Lebe begeistert und gewinne",
        author: "Frank Bettger",
        text: "Schritt für Schritt entwickelte Frank Bettger die beste Verkaufsmethode, die es bis heute gibt. Seine Erkenntnisse sind in einfacher Weise auf das Leben vieler Menschen übertragbar und könne zu einer Quelle des eigenen Lebenserfolges werden. Sein reiches Wissen, seine immense Erfahrung und seine Begeisterung gab er – zusammen mit seinem Freund Dale Carnegie – in Seminaren an zahllose Berufskollegen weiter. Bis heute ist ‚Lebe begeistert und gewinne‘ eines der weltweit meistverkauften Bücher zum Thema ‚Verkaufen‘.",
        image: "lebe.jpg",
        link: "https://www.amazon.de/gp/product/B010IMLVIE/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B010IMLVIE&linkCode=as2&tag=karriereinsti-21&linkId=9c292ce40d15b78fe9c281fa0d185fd"
      },
      {
        title: "Flight Plan",
        author: "Brian Tracy",
        text: "In seinem neuesten Buch erklärt Bestseller-Autor Brian Tracy die wahren Geheimnisse von Erfolg in Beruf und Privatleben. Er nutzt dazu die Metapher eines Fluges und erläutert, wie man nachhaltigen Erfolg wirklich erreichen kann. Das Leben ist, so Tracy, ein Langstreckenflug. Erst muss man bestimmen, wo die Reise hingehen soll, und dann genau in Richtung des Zieles fliegen.",
        image: "flightplan.jpg",
        link: "https://www.amazon.de/gp/product/B00U8T6MZA/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B00U8T6MZA&linkCode=as2&tag=karriereinsti-21&linkId=a1d80234dcdb43f13a51f544a527c6ff"
      },
      {
        title: "Der Weg zur finanziellen Freiheit: Die erste Million",
        author: "Bodo Schäfer",
        text: "<p>»Money-Coach Bodo Schäfer zeigt Unternehmern und Angestellten, wie man richtig reich wird. Er rüttelt auf und vermittelt sofort umsetzbares Wissen.« Süddeutsche Zeitung</p> <p>Drücken Sie Geldsorgen, beneiden Sie andere um ihre finanzielle Unabhängigkeit? Oder haben Sie ausreichend vorgesorgt und können sich Ihre Wünsche erfüllen? Bodo Schäfer zeigt in diesem Buch, wie wir – gerade auch in Krisenzeiten – unsere Lebensqualität entscheidend verbessern können. Die Techniken, die er verrät, sind verblüifend einfach und sofort wirksam. Wohlstand erreichen kann jeder – man muss es nur wollen und dieses Ziel diszipliniert verfolgen!</p> <p>Erfahren Sie</p> <ul> <li>wie Sie schnell Ihre Schulden loswerden</li> <li>wie Sie richtig sparen und dabei ein Vermögen aufbauen</li> <li>überraschende Methoden, mit denen Sie sofort Ihr Einkommen erhöhen</li> <li>Insiderwissen über Geldanlagen, das Ihnen keine Bank verrät.</li> </ul>",
        image: "weg.jpg",
        link: "https://www.amazon.de/gp/product/3423340002/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3423340002&linkCode=as2&tag=karriereinsti-21&linkId=2ff9edc6f6a0c9b87341f4e7f8d0bfc4"
      },
      {
        title: "Lassen Sie Ihr Hirn nicht unbeaufsichtigt!: Gebrauchsanweisung für Ihren Kopf – Neues von der Gedächtniskünstlerin",
        author: "Christiane Stenger",
        text: "Was geht in Ihrem Gehirn vor, wenn Sie diese Zeilen lesen? Wie konstruiert unser Gehirn unsere Realität? Gedächtnisweltmeisterin Christiane Stenger erklärt in ihrem Buch nicht nur anschaulich und unterhaltsam, wie die 100 Milliarden Nervenzellen in unserem Gehirn arbeiten, sie bringt sie auch gleich ordentlich auf Trab. So werden Sie genial!",
        image: "hirn.jpg",
        link: "https://www.amazon.de/gp/product/3442175577/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3442175577&linkCode=as2&tag=karriereinsti-21&linkId=031abc74c00d3a05c5c040d3f35adbd9"
      },
      {
        title: "Rich Dad Poor Dad: Was die Reichen ihren Kindern über Geld beibringen",
        author: "Robert T. T. Kiyosaki",
        text: "<p>Warum bleiben die Reichen reich und die Armen arm? Weil die Reichen ihren Kindern beibringen, wie sie mit Geld umgehen müssen, und die anderen nicht! Die meisten Angestellten verbringen im Laufe ihrer Ausbildung lieber Jahr um Jahr in Schule und Universität, wo sie nichts über Geld lernen, statt selbst erfolgreich zu werden.</p> <p>Robert T. Kiyosaki hatte in seiner Jugend einen »Rich Dad« und einen »Poor Dad«. Nachdem er die Ratschläge des Ersteren beherzigt hatte, konnte er sich mit 47 Jahren zur Ruhe setzen. Er hatte gelernt, Geld für sich arbeiten zu lassen, statt andersherum. In Rich Dad Poor Dad teilt er sein Wissen und zeigt, wie jeder erfolgreich sein kann</p>",
        image: "rich.jpg",
        link: "https://www.amazon.de/gp/product/B00KRGYEQS/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B00KRGYEQS&linkCode=as2&tag=karriereinsti-21&linkId=53fd039b61d4744afec9653046481015"
      },
      {
        title: "Das LOL²A-Prinzip: Die Vollkommenheit der Welt",
        author: "Rene Egli",
        text: "",
        image: "lol2a.jpg",
        link: "https://www.amazon.de/gp/product/3952060607/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3952060607&linkCode=as2&tag=karriereinsti-21&linkId=95d212c3d2e83104de4b92cc90984a3f"
      },
      {
        title: "Das Prinzip Gewinnen: Tun Sie alles, was Sie tun können, und Sie werden alles erreichen! (Colours of Business)",
        author: "Arthur L. Williams",
        text: "",
        image: "gewinnen.jpg",
        link: "https://www.amazon.de/gp/product/3636013394/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3636013394&linkCode=as2&tag=karriereinsti-21&linkId=f3446ee9d713c7c120c38afd9efb1767"
      },
      {
        title: "Heile Deine Gedanken: Werde Meister deines Schicksals",
        author: "James Allen",
        text: "Ändern Sie Ihre Art zu denken und Sie ändern Ihr Leben! Vor 100 Jahren schrieb ein unbekannter Engländer ein kleines Buch mit dem Titel „As A Man Thinketh“. Daraus wurde das Pionierwerk des Selbsthilfegedankens: der Schlüssel zum Erfolg liegt in unseren Gedanken und wir sollten lernen, diesen Schlüssel zu benutzen. Das Buch ist in seiner deutschen Fassung seit Jahren ein Dauerbrenner im Lüchow Verlag. Grund genug, den 100sten Geburtstag mit einer Neuauflage zu feiern. Der Text will Mut machen, diese Kraft auch einzusetzen. ‚Heile deine Gedanken‘ hat – obwohl bereits 100 Jahre alt – nichts von seiner Magie verloren.",
        image: "heile.jpg",
        link: "https://www.amazon.de/gp/product/3899013026/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3899013026&linkCode=as2&tag=karriereinsti-21&linkId=f4334d47c42d53059f4c5f4b95fc23a1"
      },
      {
        title: "Erfolgreiches Networking: Lebenslange Geschäftsbeziehungen aufbauen (Dein Erfolg)",
        author: "Tim Templeton",
        text: "Niemand mag Akquise. Und niemand braucht Akquise. Denn Ihre Kunden und Freunde verfügen über alle Kontakte, die Sie weiterbringen. Es geht darum, sie effektiv zu nutzen. Was jedoch ausdrücklich nicht heißt, nur den wachsenden Geschäftserfolg im Blick zu haben. Bestseller-Autor Tim Templeton geht es stattdessen vor allem um Ihre Beziehung zu Ihren Geschäftspartnern. Führen Sie ehrliche und aufrichtige Beziehungen zu diesen Menschen, beweisen Sie sich als integrer Mensch. Dann werden Sie auch langfristig erfolgreiche Geschäftsbeziehungen entwickeln, die Ihren Erfolg potenzieren. Dieses aufrichtige und gradlinige Buch stellt berufliche Kontakte in ein völlig neues Licht!",
        image: "getimage.jpg",
        link: "https://www.amazon.de/gp/product/B077DBY563/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B077DBY563&linkCode=as2&tag=karriereinsti-21&linkId=fc57b4bb68a8ca4ef9a68cd05057a53d"
      },
      {
        title: "Fish!™: Ein ungewöhnliches Motivationsbuch",
        author: "Stephen C. Lundin",
        text: "„Fish!™“ verrät das Erfolgsgeheimnis des weltberühmten Pike Place Fischmarkts in Seattle. Die Geschichte zeigt, wie unglaublich wichtig es ist, eine positive Lebenseinstellung zu haben. Das Autorenteam gibt dem Leser vier schlichte Handlungsmaximen mit auf den Weg, die seine Arbeits- und Lebenseinstellung revolutionieren werden. Denn wer beschließt, alles, was er ohnehin tun muss, mit Freude zu tun, motiviert sich selbst und kann ein ganzes Team erfolgreich zu Spitzenleistungen bringen.",
        image: "fish.jpg",
        link: "https://www.amazon.de/gp/product/B00XSRY7Q0/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B00XSRY7Q0&linkCode=as2&tag=karriereinsti-21&linkId=01ff98289a3d87aaeb1f3ed638b229df"
      },
      {
        title: "Sorge dich nicht – lebe!",
        author: "Dale Carnegie",
        text: "<p>In diesem Buch greift Dale Carnegie eines der wichtigsten Themen unserer Zeit auf – die alltäglichen Ängste und Sorgen, die uns an der Entfaltung unserer Möglichkeiten hindern und die es uns so schwer machen, einfach glücklich zu sein und den Tag mit Selbstvertrauen und Zuversicht zu beginnen. Die Grundsätze für ein unbesorgteres Leben, die Dale Carnegie hier aufstellt, sind anregend, für jeden Menschen nachvollziehbar und – sie lassen sich wirklich in die Praxis umsetzen.</p> Dieses Buch hilft Ihnen <ul> <li>sich das Sorgen abzugewöhnen, ehe es einen zugrunde richtet</li> <li>zu einer Lebenseinstellung zu gelangen, die Frieden und Glück bringt</li> <li>mit der Kritik anderer fertig zu werden</li> <li>geistig und körperlich auf der Höhe zu bleiben</li> <li>sich selbst zu finden</li> <li>Trübsinn in wenigen Tagen zu heilen</li> <li>an vielen Beispielen zu erkennen, was der Wille des Menschen vermag</li> <li>das Leben positiv zu verändern</li> </ul>",
        image: "sorge.jpg",
        link: "https://www.amazon.de/gp/product/3596190568/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3596190568&linkCode=as2&tag=karriereinsti-21&linkId=6efc7bd626dc3042dfb1122912a3b93e"
      },
      {
        title: "Denke nach und werde reich. Die Erfolgsgesetze",
        author: "Napoleon Hill",
        text: "Reichtum ist kein Zufall! Über zwanzig Jahre lang hat Napoleon Hill untersucht, welche Gemeinsamkeiten erfolgreiche Menschen verbindet. In Gesprächen mit 500 Millionären arbeitete er die Methoden heraus, die zum Erfolg führen und beschrieb sie in seinem Bestseller „Denke nach und werde reich“. Im vorliegenden Buch finden Sie die wichtigsten dieser Erfolgsgesetze prägnant zusammengefasst – eine hilfreiche Anleitung, das eigene Leben in materiellem Wohlstand und mit Erfolg in Beruf und Privatleben zu führen.",
        image: "denke.jpg",
        link: "https://www.amazon.de/gp/product/372052664X/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=372052664X&linkCode=as2&tag=karriereinsti-21&linkId=7ebd36382d520ab5469e585c2ec1df24"
      },
      {
        title: "Thinking Big: Von der Vision zum Erfolg",
        author: "Brian Tracy",
        text: "<p>Sind Sie bereit für die nächste Stufe des Erfolgs? Dann steigen Sie ein in Thinking Big, das Denken ohne Grenzen, und erfahren Sie, wie Sie jedes Ziel erreichen können, das Sie sich selbst geSetzt haben.</p><ul><li>Wie Sie aus Ihren Träumen konkrete Ziele machen.</li><li>Welche Ihrer Fähigkeiten Sie zum Erfolg führen.</li><li>Welches die wichtigsten Erfolgsgewohnheiten sind.</li><li>Wie Sie schnell richtige Entscheidungen treffen.</li><li>Wie Sie Misserfolge konstruktiv meistern.</li><li>Wie Sie ihr persönliches Netzwerk aufbauen.</li><li>Wie sie an Charisma gewinnen.</li></ul>",
        image: "thinking.jpg",
        link: "https://www.amazon.de/gp/product/B011RA6FD2/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B011RA6FD2&linkCode=as2&tag=karriereinsti-21&linkId=9e617e58d2d3082c46ecb762933b101c"
      },
      {
        title: "Erlebte Verkaufspraxis: Wie ich meinen Umsatz und mein Einkommen vervielfache",
        author: "Frank Bettger",
        text: "",
        image: "Download.jpg",
        link: "https://www.amazon.de/gp/product/3035000182/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3035000182&linkCode=as2&tag=karriereinsti-21&linkId=2c669c27a67486a5a71e7c16444af317"
      },
      {
        title: "The Big Five for Life: Leadership’s Greatest Secret – Was wirklich zählt im Leben",
        author: "John Strelecky",
        text: "<p>Die Erfolgsformel für Unternehmer und Führungskräfte</p><p>Wer möchte das nicht: jeden Tag mit einem Lächeln zur Arbeit gehen. Einen Job haben, der einen voll und ganz erfüllt, ja richtig glücklich macht. Eine utopische Vorstellung? Keineswegs! Wie sich unsere persönlichen Lebensziele, die »Big Five for Life«, mit unserer Arbeit in Einklang bringen lassen, zeigt John Strelecky anhand der Geschichte der wunderbaren Freundschaft zwischen dem frustrierten Angestellten Joe und dem charismatischen Unternehmer Thomas, der die Geheimnisse seines Erfolgs verrät.</p><p>Warnhinweis für Führungskräfte und Firmeninhaber: Vorsicht, nach der Lektüre dieses Buches könnte Ihr Unternehmen sich dramatisch verändern. Zum Positiven!</p>",
        image: "five.jpg",
        link: "https://www.amazon.de/gp/product/3423280190/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3423280190&linkCode=as2&tag=karriereinsti-21&linkId=0e06fbe3540bcfb1e3c7e3a10681320c"
      },
      {
        title: "Die Macht Ihres Unterbewusstseins: Das Original",
        author: "Joseph Murphy",
        text: "Dr. Joseph Murphy gilt als Wegbereiter des positiven Denkens, seine Bücher haben sich millionenfach verkauft. Sein vor 50 Jahren erschienener Weltbestseller Die Macht Ihres Unterbewusstseins hat bis heute nichts an Aktualität verloren. Denn unser Unterbewusstsein ist weitaus mächtiger, als wir annehmen – in ihm liegt der Schlüssel zum Glück. Unsere eigenen Gedanken entscheiden über Erfolg oder Niederlage, und nur wer in der Lage ist, sein Unterbewusstsein positiv zu beeinflussen, kann Schwächen in Stärken umwandeln und Herausforderungen mit Zuversicht meistern.",
        image: "Download-1.jpg",
        link: "https://www.amazon.de/gp/product/B004OL2T8C/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B004OL2T8C&linkCode=as2&tag=karriereinsti-21&linkId=149970bd918c4da1cdc04ca6d9a1edcb"
      }
    ]
  }),
  methods: {
    getImage: function(name) {
      return require("@/assets/images/books/" + name);
    }
  }
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
</style>